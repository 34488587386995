@keyframes gau-fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes gau-fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.gau-fade-out {
    animation-timing-function: ease-in-out;
    animation-duration: 600ms;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-duration: 600ms;
    z-index: 0;
    animation-name: gau-fadeout;
    -webkit-animation-name: gau-fadeout;
}

.gau-preloader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 999999;
}

.gau-preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow: visible;
}

#gau-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-transform: uppercase;
    pointer-events: none;
}