#gau-screen_1 {
    background-image: url("./../../../assets/img/Fondo_typeform_laptop_shiva.png");
    background-size: cover;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 9;
    cursor: pointer;
}

#gau-screen_2 {
    background-image: url("./../../../assets/gifs/choose.gif");
    background-size: cover;
    background-position: center;
    z-index: 9;
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    #gau-screen_1 {
        background-image: url("./../../../assets/img/Fondo_typeform_movil_shiva.png");
    }
    #gau-screen_2 {
        background-position: -510px;
    }
    #gau-screen_3 {
        font-size: 12px;
    }
    #gau-screen_4 {
        font-size: 12px;
    }
    #gau-screen_7 {
        font-size: 14px;
    }
    #gau-screen_8 {
        font-size: 10px;
    }
    #gau-screen_16 {
        font-size: 10px;
    }
}

#gau-screen_3 {
    background-image: url("./../../../assets/gifs/sand.gif");
    background-size: cover;
    background-position: center;
    z-index: 8;
    cursor: pointer;
}

#gau-screen_4 {
    background-image: url("./../../../assets/gifs/pregunta1.gif");
    background-size: cover;
    background-position: center;
    z-index: 7;
}

#gau-screen_5 {
    background-image: url("./../../../assets/gifs/win.gif");
    background-size: cover;
    background-position: center;
    z-index: 6;
    color: white !important;
    cursor: pointer;
}

#gau-screen_6 {
    background-image: url("./../../../assets/gifs/lose.gif");
    background-size: cover;
    background-position: bottom center;
    z-index: 5;
}

#gau-screen_7 {
    background-image: url("./../../../assets/gifs/paparazzi.gif");
    background-size: cover;
    background-position: center;
    z-index: 4;
    cursor: pointer;
}

#gau-screen_8 {
    background-image: url("./../../../assets/gifs/pregunta2.gif");
    background-size: cover;
    background-position: center;
    z-index: 3;
}

#gau-screen_9 {
    background-image: url("./../../../assets/gifs/shakira.gif");
    background-size: cover;
    background-position: center;
    z-index: 2;
    cursor: pointer;
}

#gau-screen_10 {
    background-image: url("./../../../assets/gifs/nba.gif");
    background-size: cover;
    background-position: center;
    z-index: 1;
}

#gau-screen_16 {
    background: black;
    background-image: url("./../../../assets/gifs/friends.gif");
    background-size: cover;
    background-position: center;
    /* z-index: -6; */
}

.gau-screenview {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default;
}

@media only screen and (max-width: 600px) {
    .gau-inner {
        min-width: 70%;
    }
}

.gau-inner {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.gau-blinky {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        -webkit-filter: invert(90%);
        filter: invert(90%);
    }
}

.gau-big-text {
    text-transform: uppercase;
}

.gau-button {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    width: 300px;
    padding: 20px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    transition: .3s;
    text-transform: uppercase;
    cursor: pointer;
    margin: 10px;
}

.gau-button:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: .5s;
    background-color: white;
    color: black;
    border: 2px solid black;
}

.gau-button:focus {
    outline: none;
}

.gau-play {
   background-color: black;
    color: white;
    position: absolute;
    width: 15%;
    bottom: 10%;
    left: 45%;
}

@media only screen and (max-width: 768px) {
    .gau-play{
        width: 30%;
        padding: 10px 30px;
        font-size: 14px;
        bottom: 34%;
        left: 35%;
    }
}
