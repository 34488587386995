/* General */
@font-face {
  src:url(./font/itc-avant-garde-gothic-std-bold-condensed-5895705e8fe31.otf);
  /* src: url(./font/itc-avant-garde-gothic-std-demi-condensed-589571f095e47.otf); */
  font-family: ITC_Semi;
}

@font-face {
  src:url(./font/baskerville/Baskerville-SemiBold.ttf);
  /* src: url(./font/itc-avant-garde-gothic-std-demi-condensed-589571f095e47.otf); */
  font-family: Baskerville;
}

@font-face {
  src:url(./font/itc-avant-garde-gothic-std-bold-589572c7e9955.otf);
  /* src: url(./font/itc-avant-garde-gothic-std-demi-condensed-589571f095e47.otf); */
  font-family: ITC_Bold;
}

html {
  scroll-behavior: smooth;
  /* cursor: url(hand_small.png), auto !important;  */
  cursor: url(hand_small.png), auto !important; 
  
}

html, body {
  overflow-x: hidden;
  font-size: 16vh !important;
  line-height: 1vh;
  /* cursor: url(hand_small.png), auto !important;  */
}

body {
  margin: 0;
  font-family: ITC_Semi, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F5F5F5; */
  /* background-image: url(./components/styles/noise.png) repeat rgb(244,238,224); */
  background-color: rgb(244,238,224) !important;
  /* cursor: url('/src/hand_coloured.png') !important; */
  /* cursor: url(hand_small.png) 15 15 !important; */

}

input:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover{
  cursor: url(hand_black.png), auto !important; 
}

.blur{
  opacity: 0.2;
}

.tinder-button:hover{
  cursor: url(hand_black.png), auto !important; 
}



.slider-hand {
  cursor: url(hand.png), auto !important;
}























/* Trial Code for Following Div */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hand-black:hover {
  cursor: url(hand_black.png), auto !important; 
}

.main-cursor,
.secondary-cursor {
  z-index: 10000;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  position: fixed;
}

.main-cursor {
  transition: opacity 1s cubic-bezier(0.77, 0, 0.175, 1);
  animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  mix-blend-mode: difference;
  height: 20vh;
  width:20vw;
  background-color: red;
}
.main-cursor-background {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
}

.secondary-cursor {
  width: 60px;
  height: 60px;
}


.cursor-background {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid black;
  position: relative;
 
  /* &:before {
    left: 10px;
  }

  &:after {
    right: 10px;
  } */
}

.cursor-background::after, .cursor-background::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: white;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    animation: fadeOut 0.75s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
}






/* ---------- Tinder Code ---------- */




#root {
  /* text-align: center;
  display: flex;
  justify-content: center; */
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(#fff, #999); */
  /* background: linear-gradient(#e66465, #9198e5); */
}

/* #root>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.app>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row>* {
  margin: 5px;
}

h1 {
  font-family: ITC_Semi, ¯'Damion', cursive;
  color: #fff;
  /* text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30); */
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
  cursor: url("hand_hover_old.png"), auto !important;
}

.cardContainer {
  width: 90vw;
  max-width: 20vw;
  height: 60vh;
}

.cardContainerM {
  width: 90vw;
  max-width: 80vw;
  height: 50vh;
}

.card {
  position: relative;
  width: 80vw;
  max-width: 20vw;
  height: 55vh;
  /* box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cardM {
  position: relative;
  width: 80vw;
  max-width: 70vw;
  height: 55vh;
  /* box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cardContent {
  width: 100%;
  height: 100%;
}

/* .swipe:last-of-type {

} */

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  font-size: 6vh;
  justify-content: center;
  display: flex;
  color: #fff;
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 20px;
  display: flex;
}

.buttons button {
  flex-shrink: 0;
  padding: 2vh;
  border-radius: 1vh;
  border:none;
  transition: 200ms;
  margin: 0 10px;
  font-weight: bolder;
  height:13vh;
  /* width: 160px; */
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
}

.buttons button:hover {
  transform: scale(1.05);
}

.Mbuttons {
  margin: 10vh;
  display: flex;
}

.Mbuttons button {
  flex-shrink: 0;
  padding: 2vh;
  border-radius: 1vh;
  border:none;
  transition: 200ms;
  margin: 0 2vh;
  font-weight: bolder;
  height:10vh;
  /* width: 160px; */
  box-shadow: 0px 0px 2vh 0px rgba(0,0,0,0.20);
}

.Mbuttons button:hover {
  transform: scale(1.05);
}

.blink {
    animation: animate 1.5s linear infinite;
    color:black;
    font-size:5vh;
    font-weight:bold;
}

@keyframes animate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}

.tinder-button input[type="radio"] {
  display: none;
}


input[type="radio"] + label > img {
  /* border: 0.25vh solid black; */
  /* background-color: white; */
  transition: all 0.5 ease-in;
  /* cursor:url(hand_small.png), auto; */
  cursor:url(hand_small.png), auto;
}


input[type="radio"]:checked + label > img {
  /* border: 0.25vh solid black; */
  /* background-color: white; */
  transform: scale(1.2,1.2);
  transition: all 0.5 ease-in;
  cursor:url(hand_small.png), auto;
}



/* ---------- Banner Stickers Animation ---------- */

@keyframes menu{
  0% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.75);
  }
}

.animate-menu {
  animation: menu 2s linear infinite;
  transition: all 1s;
}

.animate-menu:hover {
  animation: none;
}

@keyframes live{
  0% {
    opacity:0;
  }
  5% {
    opacity:1;
  }
  100% {
    opacity:1;
  }
}

.animate-live {
  animation: live 3s linear infinite;
}


@keyframes bounce {
  from {
      transform: translate3d(0, 0, 0);
  }
  to {
      transform: translate3d(0, 50px, 0);
  }
}

/* @-webkit-keyframes bounce {
  from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
  to {
      -webkit-transform: translate3d(0, 50px, 0);
      transform: translate3d(0, 50px, 0);
  }
}  */

.animate-shop {

  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
  -webkit-animation: bounce 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(
  .5, 0.05, 1, .5);
  -webkit-animation-iteration-count: infinite;
}

.animate-shop:hover{
  animation: bounce 0.2s alternate infinite;
}



@keyframes friend{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-friend {
  animation: friend 10s linear infinite;
}

.animate-friend:hover{
  
  animation:friend 2s linear infinite;  
  
}

@keyframes where{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.animate-where {
  animation: where 10s linear infinite;
}


.animate-where:hover {
  animation:where 2s linear infinite;  
}

.wave:hover  .food-sticker {
    
    transform: rotate(-10deg);
    transform-origin: 50% 50%;

    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
          
  
}

/* .fuck:hover{
  background-color:red;
  cursor: pointer;
} */

/* Gauthier code */

.gau-screen_container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.gau-screen_container h4 {
  position: absolute;
  bottom: 0;
  font-family: sans-serif;
  text-transform: uppercase;
  opacity: .4;
  margin-bottom: 50px;
  text-align: center;
  transform: translate(-50%, -50%);
  left: 50%;
  color: white;
}

.gau-screen_container h2 {
  color: white;
}

.gau-h2 {
  font-size: 10vh;
  line-height: 1.2;
  font-weight: bold;
  text-shadow: 3px 3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000, -3px -3px 0 #000, 3px 0px 0 #000, 0px 3px 0 #000, -3px 0px 0 #000, 0px -3px 0 #000;
}

.gau-h4 {
  font-size: 3vh;
  color: #000;
}

.filler{
  background: aqua;
  height: 25vh;
  width: 100vw;
}


@media screen and (max-width:480px){
  .gau-h2 {
    font-size: 6vh;
    line-height: 1.2;
  }

  .gau-h4 {
    font-size: 2vh;
    line-height: 1.2;
  }
}


/* .screen_container h4 {
  position: absolute;
  bottom: 0;
  font-family: sans-serif;
  text-transform: uppercase;
  opacity: .4;
  margin-bottom: 50px;
  text-align: center;
  transform: translate(-50%, -50%);
  left: 50%;
  color: white;
}

.screen_container h2 {
  color: white;
}

h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4em;
  line-height: 1.3em;

  font-weight: bold;
  text-shadow: 3px 3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000, -3px -3px 0 #000, 3px 0px 0 #000, 0px 3px 0 #000, -3px 0px 0 #000, 0px -3px 0 #000;
}

h4 {
  font-size: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}

button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
  width: 300px;
  padding: 20px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  transition: .3s;
  text-transform: uppercase;
  cursor: pointer;
  margin: 10px;
}

button:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: .5s;
  background-color: white;
  color: black;
  border: 2px solid black;
}

button:focus {
  outline: none;
}
 */
