.gau-showroom-window_close {
    position: absolute;
    top: 35px;
    right: 35px;
    z-index: 1000;
    transition: all .3s;
}

.gau-showroom-window_close:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: all .3s;
}

.gau-invert {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.gau-escape {
    width: 35px;
}

@media only screen and (max-width: 600px) {
    .gau-escape {
        width: 25px;
    }
}